import React from 'react'

export function AboutPage(){
    return(
        <div>
            <AboutChris/>
            <br/>
            <AboutDan/>
        </div>
    )
}

function AboutChris(){
    return (
        <div style = {{width: "75vw"}}>
            <p style = {{textAlign: 'center'}}>
                <img src = {'/images/chris.jpg'} align = "top" style = {{width: '25vh', height: "35vh", display:" inline-block", margin: "10px 30px 0 30px"}} alt= "its-a-me"/>
            </p>

            <p style = {{textAlign: 'center'}}>
                Chris Hartley, Lead Software Architect
            </p>

            <p>
                I have been working with businesses as a fullstack architect and engineer
                to design innovative products and build software solutions to automate complex tasks for over 5 years.
                In that time, I've lead several project teams and helped to co-found SparkLeaf Technologies, a cutting-edge
                technology company to link consumers with personalized, high-quality cannabis products.
            </p>

            <p>
                While many businesses use off-the-shelf solutions like Wordpress or GoDaddy for their websites and applications,
                there is no comparison to having custom built software using the most innovative technology. Fullstack
                Software Innovations can help your business improve customer satisfaction and retention with tools that are
                custom engineered for your needs whether it is business analytics, web portals, or on-site software solutions.
            </p>
        </div>
    )
}

function AboutDan(){
    return (
        <div style = {{width: "75vw"}}>
            <p style = {{textAlign: 'center'}}>
                <img src = {'/images/dan.jpg'} align = "top" style = {{width: '25vh', height: "35vh", display:" inline-block", margin: "10px 30px 0 30px"}} alt= "its-a-me"/>
            </p>
            <p style = {{textAlign: 'center'}}>
                Dan Snyder, Lead Software Engineer
            </p>

            <p>
                I have been building software for over a decade in a wide variety of systems ranging from
                microcontrollers for robotics and medical devices, to IBM mainframes, to custom machine learning hardware.
                I am a natural-born problems solver, and while some problems are harder than others, I have never
                found one I can't manage.
            </p>

            <p>
                With Fullstack Software Innovations, your business can leverage our engineering team's extensive
                technology background to build fast, powerful software for any business needs.
            </p>
        </div>
    )
}