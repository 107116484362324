import React from 'react';
import './Book.css';
import { HashRouter} from 'react-router-dom'


export class Book extends React.Component{
    constructor(props) {
        super(props);
        if (window.location.href.endsWith('/')) window.history.replaceState({},"", `#${this.props.defaultPage}`)
    }

    render(){
        return(
            <HashRouter>
                {this.props.children}
            </HashRouter>
        )
    }


}




