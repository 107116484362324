import React, {useState} from "react";
import {CellSelector, Spreadsheet} from "virtual-spreadsheet";


export function SpreadsheetDemo () {
    /*
     *  The csv prop passed to spreadsheet should use commas to delimit columns and
     *  newline to delimit rows. (TODO: add custom delimiters)
     */
    const csv = generateSample()

    /* Used to demonstrate the CellSelector. Not necessary for operation */
    const [selectedData, setData] = useState([['']])

    const getSelectedData = (coordinate, cells) => {
        /*
         * CellSelector retrieves the row, column or cell selected in a 2-d array
         * Can be used for any operations you need to perform when data is selected
         */
        setData(CellSelector(coordinate, cells))
    }

    /* Do any state updates you need by passing a function to the onUpdate prop */
    const processUpdate = (coordinate, newVal, cells) => {
        cells[coordinate.row][coordinate.col] = newVal
        setData(CellSelector(coordinate, cells))
    }



    return (
        <div style = {{ height:'50vh', width:'75vw', marginTop: 10, marginBottom: 10}}>
            <Spreadsheet
                csv={csv}

                notARealProp='You can attach some extra event handlers if needed'
                onCellSelect={getSelectedData}
                onCellUpdate={processUpdate}

                notARealProp2='Cell size and font cannot be set with cellStyle'
                cellWidth='auto'
                cellHeight={25 /* number */}
                cellFont='18px Arial'

                notARealProp3='Style the cells using the props below'
                rowHeaderStyle={{ color: '#ffffff', background: '#0077cc' }}
                columnHeaderStyle={{ color: '#ffffff', background: '#0077cc' }}
                cellStyle={{ color: '#000000', background: '#ffffff' }}
                activeCellStyle={{ color: '#ffffff', background: '#33aaff' }}
            />
        </div>
    )
}


function generateSample(){
    let csv = ""
    for(let r = 0; r < 100; r++){
        let row = "";
        for(let c = 0; c < 25; c++){
            row += `${r+1}-${c+1},`
        }
        row.substring(0,row.length-1) //remove trailing ,
        csv += row + '\n'
    }
    return csv.substring(0,csv.length-1) //remove trailing \n
}