import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {Book} from "../Book/Book.js"
import {Page} from "../Book/Page.js"
import {AboutPage} from "../app_pages/AboutPage.js"
import {BlogHome} from "../app_pages/blog/BlogHome.js"
import '../Book/Book.css';
import {Bookmark} from "../Book/Bookmark";
import {Glossary} from "../Book/Glossary";
import {ProjectPage} from "../app_pages/projects/Projects";

function App() {

    let mainMenuStyle = {minWidth: '12vw', maxWidth:'15vw', display: 'inline-block', textAlign:"center", margin: 10, height:"80vh"};
    let menuItemStyle = {minWidth: '12vw',maxWidth:'15vw', display:"block", textAlign: "center"};

    let [test, setTest] = useState("test")
    fetch(`${window.location.origin}/blog/test.txt`)
        .then(csv => csv.text())
        .then(txt => setTest(txt))

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>

            <body className="App-body">
            <Book defaultPage = {"/about"} style = {{width: "100vw"}}>
                <Glossary style = {mainMenuStyle} itemStyle = {menuItemStyle}>
                    <Bookmark to = "/about" label = "About"/>
                    { /* <Bookmark to = "/blog" label = "Blog"/> */}
                    <Bookmark to = "/projects"  label = "Open Source" />
                </Glossary>

                <Page path = "about"><AboutPage/></Page>
                {/* <Page path = "/blog"><BlogHome/></Page> */}
                <Page path = "projects"><ProjectPage/></Page>
                <Page path = "test">{test}</Page>
            </Book>
            </body>
        </div>
    );
}


export default App;
