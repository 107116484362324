import React from 'react'
import {Bookmark} from "../../Book/Bookmark";
import {DisplayGlossary} from "../../Book/Glossary";
export function BlogPost(props){
    return(
        <div style = {{width: '75vw',height: '85vh', display:"inline-block", margin: '0 auto'}}>
            <Bookmark to={"/blog"} onClick = {() => DisplayGlossary("/blog")} label = "All Posts" style = {{width: '50vw', textAlign:'center', margin:'10px auto'}}/>

            <div  style = {{height: '80vh', overflow: 'auto'}}>
                {props.children}
            </div>

        </div>

    )
}