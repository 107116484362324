import React from 'react';
import {formatPath} from "./Paths"
// noinspection ES6CheckImport
import {Route} from "react-router-dom";

export function Page(props){
    let root = (props.root) ? props.root : '';
    let fullPath = formatPath(root, props.path);
    let content = props.children; //React.Children.map(props.children, (element) => React.cloneElement(element, {style:{pageStyle}}))
    return(
        <Route path={fullPath}>
            <div style = {{width:'75vw'}}>
                {content}
            </div>

        </Route>
    )
}