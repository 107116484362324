import React from 'react'
import {Post1} from "./posts/Post1.js"
import {Page} from "../../Book/Page.js"
import { BlogPost } from './BlogPost.js'
import {Bookmark} from "../../Book/Bookmark";
import {Glossary} from "../../Book/Glossary";

export class BlogHome extends React.Component{

    render(){
        let MenuStyle = {width:'25vw', textAlign:"center", margin: 10, height:"80vh", display:"inline-block"};
        let menuItemStyle = {width:'25vw', display:"inline-block", textAlign: "center"};

        return(
            <div>
                <Glossary style = {MenuStyle} itemStyle = {menuItemStyle} hideOnSelection={true} root = "/blog">
                    <Bookmark to = "/blog/welcome" label = "Welcome to my SPA"/>
                    <Bookmark to = "/blog/test" label = "test"/>
                </Glossary>

                <Page path= "/blog/welcome">
                    <BlogPost><Post1/></BlogPost>
                </Page>

                <Page path = "/blog/test">
                    <BlogPost>
                        <Glossary style = {MenuStyle} hideOnSelection={true} root = "/blog/test">
                            <Bookmark to = "/blog/test/welcome" label = "Welcome to my SPA"/>
                            <Bookmark to = "/blog/test/eek" label = "test"/>
                        </Glossary>
                        <Page path = "/blog/test/eek"><Bookmark to={"/profile"}  label = "All Posts" style = {{width: '50vw', textAlign:'center', margin:'10px auto'}}/></Page>
                    </BlogPost>
                </Page>
            </div>
        )
    }
}