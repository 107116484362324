import React from 'react'

export function Post1(){
    return (
        <div>
            <p>Welcome to my React-powered SPA!</p>

            <p>
                For those unfamiliar with the terms, SPA is an acronym for single-page-app and React (released by
                Facebook Inc. under Creative Commons license)
                is the Javascript library that I've chosen to write it in. If you haven't had the chance to experience a
                SPA, please try clicking some of the pages on this site and see how
                refreshing the load times of a well-programmed SPA can be!
            </p>

            <p>
                Traditionally, web sites serve individual pages as they a requested by a site-visitor. The time that it
                takes for their request to make its way
                to the websites server and back the visitor with the requested HTML page is often an unnecessary data
                bottleneck that frustrates visitors from slow networks.
            </p>

            <p>
                React allows for the building of reusable HTML components integrated seamlessly into Javascript classes
                and functions with its Javascript XML (JSX) compiler.
                Webpack is responsible for compiling the JSX files into minified bundles which get sent all at once to
                the site visitor and React-Router brings the SPA to life
                by serving requested pages from the browser itself, and not the server!
            </p>
        </div>

    );
}