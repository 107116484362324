import {Glossary} from "../../Book/Glossary";
import {Bookmark} from "../../Book/Bookmark";
import {Page} from "../../Book/Page";
import React from "react";
import {SpreadsheetDemo} from "./virtual-spreadsheet/SpreadsheetDemo";

export function ProjectPage() {
    let MenuStyle = {width: '25vw', textAlign: "center", margin: 10, height: "80vh", display: "inline-block"};
    let menuItemStyle = {width: '25vw', display: "inline-block", textAlign: "center"};

    return (
        <div>
            <Glossary style={MenuStyle} itemStyle={menuItemStyle} hideOnSelection={true} root="/projects">
                <Bookmark to="/projects/virtual-spreadsheet" label="Virtual Spreadsheet"/>
            </Glossary>

            <Page path="/projects/virtual-spreadsheet">
                <a href={'https://github.com/FullstackSoftwareInnovations/virtual-spreadsheet'}>Github</a> <br/>
                <a href={'https://www.npmjs.com/package/virtual-spreadsheet'}>npm</a><br/><br/>
                virtual-spreadsheet is an npm package written for React.js. Unlike many of the current spreadsheet options available,
                it is capable of rendering millions of cells of data, comes with a customizable UI, and has hooks for additional
                data processing.
                <SpreadsheetDemo/>
            </Page>
        </div>

    )
}