import React from 'react';
import {NavLink} from 'react-router-dom'


export function Bookmark(props){
    let className = (props.className) ? props.className : "TabHeader";
    let activeClassName = (props.activeClassName) ? props.activeClassName : "TabHeader-selected";

    return(
        <NavLink className = {className}
                 activeClassName={activeClassName}
                 to={props.to}
                 onClick={props.onClick}
                 style={{ textDecoration: 'none', ...props.style}}>

                    {props.label}
        </NavLink>
    )
}