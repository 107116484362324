import * as React from "react";
import autoBind from "react-autobind/src/autoBind";
import {formatPath} from "./Paths";


export class Glossary extends React.Component{
    constructor(props) {
        super(props);
        autoBind(this);
        let root = formatPath(this.props.root);
        let hide = this.props.hideOnSelection && !window.location.hash.endsWith(root);
        this.state = {
            root: root,
            showMenu: !hide
        }
    }

    componentDidMount() {
        window.addEventListener("popstate", this.setMenuState); //register on history forward/back listener to decide if menu should be opened
        window.addEventListener(this.state.root, this.showMenu); //register listener to allow a navlink to re-render
    }

    componentWillUnmount() {
        // unregister menu listeners or this glossary
        window.removeEventListener("popstate", this.setMenuState);
        window.removeEventListener(this.state.root, this.showMenu);
    }

    setMenuState(){
        if (this.props.hideOnSelection && !window.location.hash.endsWith(this.state.root)) this.hideMenu();
        else this.showMenu()
    }

    hideMenu(){
        this.setState({showMenu: false})
    }

    showMenu(){
        this.setState({showMenu: true})
    }

    render(){
        let self = this;
        if (!this.state.showMenu) return null;

        let children = React.Children.map(this.props.children, child =>{
            let newProps = {
                onClick:(self.props.hideOnSelection) ? self.hideMenu : null,
                style:{...self.props.itemStyle, ...child.props.style},
            };
            return React.cloneElement(child, newProps );
        });

        return(
            <div className = "HeaderContainer" style = {this.props.style}>
                {children}
            </div>
        )
    }

}

export function DisplayGlossary(glossaryRoot){
    window.dispatchEvent(new Event(formatPath(glossaryRoot)));
}


